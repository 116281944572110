<template>
  <div>
    <div class="face-container" @click="photograph">
      <div class="face-tip">
        <div class="tip" v-if="type==1">保持面部在框定范围内</div>
        <div class="tip" v-else-if="type==2">请将舌头靠近摄像头</div>
        <div class="tip" v-else>请将舌头抵住上颚，尽量露出舌底</div>
        <div class="tip col">点击屏幕拍摄</div>
      </div>
      <div class="face-camera">
        <img src="../../../images/face_bg.png" alt=" " class="face_bg"/>
        <div class="video">
          <canvas ref="canvas" style="display: none" width="1200" height="900"></canvas>
          <video ref="video" autoplay></video>
        </div>
      </div>
      <div class="face-con"/>
    </div>
    <div class="loading flex flex-x flex-y" v-if="loading">
        <div class="loading-box">
          <div class="loading-icon">
            <van-loading />
            <div class="loading-ui">上传中</div>
          </div>
        </div>
      </div>
      <div class="loading black flex flex-x flex-y" v-if="no_show">
          <div class="loading-box">
              <div class="load-bac">
                <img src="../../../images/false.png" alt=" " class="icon-img"/>
                <div class="load-no">{{no_show}}</div>
                <img src="../../../images/face1.png" alt=" " class="load-img" v-if="type==1"/>
                <img src="../../../images/tongues.png" alt=" " class="load-img" v-else/>
                <div class="again" @click="photoFun">重新检测</div>
              </div>
          </div>
      </div>
      <div class="loading black flex flex-x flex-y" v-if="explain">
          <div class="loading-box">
              <div class="load-bac">
                <img src="../../../images/status-ok.png" alt=" " class="icon-img"/>
                <div class="load-explain">{{explain}}</div>
              </div>
          </div>
      </div>
  </div>
</template>
<script>
  import "./camera.scss";
  import { mapActions , mapMutations , mapState } from "vuex";
  export default {
    name: "camera",
    data () {
      return {
        imgSrc: "",
      };
    },
    props: ["type"],
    created: function () {
      if (sessionStorage && (sessionStorage.device === "android" && !sessionStorage.camera)) {
        this.startUpAndroidCamera ();
      } else {
        this.startUpWebCamera ();
      }
    },
    computed:{
      ...mapState('step',['show','loading','no_show','explain']),
    },
    methods: {
      // 启动H5摄像头
      startUpWebCamera () {
        if (navigator.mediaDevices) {
          navigator.mediaDevices.getUserMedia ({ audio: false, video: true }).then (stream => {
            this.$refs["video"].srcObject = stream;
          }).then (() => {
            this.$refs["video"].play ();
          });
        }
      },
      // 启动安卓（设备端）摄像头
      startUpAndroidCamera () {
        window["ReactNativeWebView"].postMessage ("photo");
        window.obj = {};
        var _this = this;
        Object.defineProperty(window.obj, "url", {
          get: function () {
            return '';
          },
          set: function (newValue) {
            _this.axiosData({
              data:{
                photo:newValue,
                type:_this.$props.type
              },
              _this:_this
            })
          },
        });
      },

      photoFun(){
        this.againFun();
        if (sessionStorage && (sessionStorage.device === "android" && !sessionStorage.camera)) {
          this.startUpAndroidCamera ();
        }
      },

      // 拍照
      photograph () {
        let canvas = this.$refs["canvas"];
        let ctx = canvas.getContext("2d");
        ctx.drawImage(
            this.$refs.video,
            0,
            0,
            canvas.width,
            canvas.height
        );
        const image = canvas.toDataURL('image/jpeg');
        this.axiosData({
          data:{
            photo:image,
            type:this.$props.type
          },
          _this:this
        })
      },
      ...mapMutations ("step", ["againFun"]),
      ...mapActions ("step", ["axiosData"])
    }
  };
</script>
